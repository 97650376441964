const config = {
    api: {
        baseUrl: 'https://api-test.sundstroms.fi/'
    },
    environment: {
        devMode: 'true',
        versionCheckInterval: 60000,
        version: 'c0bd5b824a94af808229a6b1c64c43944eb37a66'
    },
    ui: {
        timeouts: {
            messageTimeout: 3500,
        },
        mainColor: '#FF0000',
        contact: 'Alexander Tupeli'
    },
    identityServer: {
        authority: 'https://idp-test.sundstroms.fi'
    },
    lunchServer: {
            deadlineConfig: {
                hour: 15,
                minute: 0,
                second: 0,
                millisecond: 0
            }
        },
    blockedInventories: [2, 3],
    blockedInventoriesRentEquipment: [1, 2],
    links: {
        marto: 'https://mail.sundstromaboy.com/test/marto/login.php',
        ontime: ' ',
        sympa: ' ',
        pro24_fi: ' ',
        pro24_sv: ' ',
        ilmoituskanava_fi: ' ',
        ilmoituskanava_sv: ' '
    }
};

export default config;
